import { FC } from 'react'

import { styles } from './styles'

interface ApiErrorProps {
  title: string
  error: string
  onReload: () => unknown
  loading: boolean
}

export const ApiError: FC<ApiErrorProps> = ({ title, error, onReload, loading }) => {
  return (
    <main css={styles.main}>
      <div css={styles.container} data-testid="api-error">
        <h1 css={styles.title}>{title}</h1>

        <div css={styles.message.container}>
          <h1 css={styles.message.title}>Error</h1>

          <div css={styles.message.subtitle}>
            <p>Something went wrong:</p>
            <p>{error}</p>
          </div>

          <button
            css={!loading ? styles.btn : [styles.btn, styles.btnLoading]}
            type="button"
            onClick={onReload}
          >
            Reload
          </button>
        </div>
      </div>
    </main>
  )
}
