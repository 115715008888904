import ContentLoader from 'react-content-loader'

export const FieldLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={600}
    height={42}
    viewBox="0 0 600 42"
    backgroundColor="#EBEBEB"
    foregroundColor="#ffffff"
    {...props}
  >
    <rect x="85" y="5" rx="3" ry="3" width="188" height="19" />
    <rect x="85" y="31" rx="3" ry="3" width="52" height="14" />
    <rect x="6" y="4" rx="2" ry="2" width="70" height="42" />
  </ContentLoader>
)
