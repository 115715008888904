import { FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from 'firebase/app'

import { firebaseErrorLabels } from '~/errors'
import { firebaseApp } from '~/firebase'
import { Analytics, AnalyticsEvent, mainStore } from '~/stores'
import { styles } from './styles'

interface ConfirmEmailScreenProps {}

export const ConfirmEmailScreen: FC<ConfirmEmailScreenProps> = () => {
  const [error, setError] = useState('')
  const [showCounter, setShowCounter] = useState(false)
  const [seconds, setSeconds] = useState(60)

  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>(null)

  // get firebase user via observer and then unsubscribe from it
  useEffect(() => firebaseApp.auth().onAuthStateChanged(setFirebaseUser), [])

  useEffect(() => {
    if (firebaseUser) {
      sendConfirmationEmail()
    }
  }, [firebaseUser])

  // refresh user to check if email is confirmed
  useEffect(() => {
    const interval = setInterval(() => {
      firebaseApp.auth().currentUser?.reload()

      if (firebaseApp.auth().currentUser?.emailVerified) {
        mainStore.emailVerified = true
      }
    }, 3000)

    return () => clearInterval(interval)
  }, [])

  const startCountdown = () => {
    setShowCounter(true)

    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1)
    }, 1000)

    return setTimeout(() => {
      clearInterval(interval)
      setShowCounter(false)
      setSeconds(60)
    }, 1000 * 60)
  }

  const sendConfirmationEmail = () => {
    console.debug('sending confirmation email')

    setError('')

    return firebaseUser
      ?.sendEmailVerification()
      .then(() => {
        startCountdown()
        Analytics.logEvent(AnalyticsEvent.ResendEmailSuccess, {
          email: firebaseUser.email,
        })
      })
      .catch((error) => {
        // If resending fails, enable button again, without timeout.
        setShowCounter(false)
        setSeconds(60)

        if (firebaseErrorLabels.has(error.code)) {
          setError(firebaseErrorLabels.get(error.code) as string)
        } else {
          console.error('failed to send confirmation email', error)
          setError('Failed to send confirmation email')
        }
        Analytics.logEvent(AnalyticsEvent.ResendEmailFailure, {
          error,
          email: firebaseUser.email,
        })
      })
  }

  return (
    <main css={styles.main}>
      <div css={styles.form.container}>
        <h1 css={styles.form.title}>Confirm your email</h1>

        <p css={styles.sent}>We’ve sent confirmation email to:</p>
        {firebaseUser?.email && <p css={styles.email}>{firebaseUser.email}</p>}
        <p css={styles.check}>
          Check your email and click on the confirmation link to continue
        </p>
        {error && <p css={styles.form.error}>{error}</p>}

        <input
          type="submit"
          value={showCounter ? `Resend again in ${seconds}s` : `Resend`}
          css={showCounter ? styles.form.submit.off : styles.form.submit.on}
          disabled={showCounter}
          onClick={sendConfirmationEmail}
        />

        <Link css={styles.form.link} to="/create-account" onClick={mainStore.signOut}>
          Sign out
        </Link>
      </div>
    </main>
  )
}
