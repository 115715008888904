import type { AuthRegisterUser, User } from '~/types'
import { requestApi } from '~/request-api'

type ApiRegisterUserParams = {
  firebaseIDToken: string
  user: AuthRegisterUser

  retries?: number
}

export const apiRegisterUser = (
  params: ApiRegisterUserParams,
): Promise<{ authToken: string; user: User }> => {
  const { name, tags, username, role } = params.user

  return requestApi<{ authToken: string; user: User }>({
    url: '/auth/register',
    options: {
      method: 'POST',
      body: JSON.stringify({
        user: { name, username, role, tags },
        idToken: params.firebaseIDToken,
      }),
    },
  }).catch((error) => {
    const retries = params?.retries || 0

    // retry request 3 times
    if (retries < 3) {
      return apiRegisterUser({ ...params, retries: retries + 1 })
    }

    throw error
  })
}
