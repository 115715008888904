import { FC, useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import debounce from 'lodash.debounce'

import { AuthRegisterUser } from '~/types'
import { TagsInput } from '~/components'
import { firebaseApp } from '~/firebase'
import { Analytics, AnalyticsEvent, mainStore } from '~/stores'
import { firebaseErrorLabels } from '~/errors'

import { apiRegisterUser } from './api'
import { apiCheckUsernameAvailability } from '~/api'
import { Checkbox } from './components'
import { styles } from './styles'

interface CreateAccountScreenProps {}

export const CreateAccountScreen: FC<CreateAccountScreenProps> = () => {
  const [user, setUser] = useState<AuthRegisterUser>({
    email: '',
    name: '',
    password: '',
    tags: [],
    username: '',
    role: 'artist',
  })
  const navigate = useNavigate()

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [termsAccepted, setTermsAccepted] = useState(false)
  const [emailsAccepted, setEmailsAccepted] = useState(true)
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>()
  const [isUsernameValid, setIsUsernameValid] = useState<boolean>()

  const tagsLimitExceeded = user.tags === undefined ? true : user.tags!.length < 3

  const areCredentialsFilled = Boolean(
    user.name?.trim() &&
      user.username?.trim() &&
      user.email?.trim() &&
      user.password &&
      user.tags?.length,
  )

  const isSubmitButtonEnabled =
    areCredentialsFilled &&
    termsAccepted &&
    usernameAvailable &&
    isUsernameValid &&
    !loading

  const checkUsernameWithDelay = useCallback(
    debounce(
      async (username: string) =>
        apiCheckUsernameAvailability!(username)
          .then(({ usernameAvailable, usernameValid }) => {
            setUsernameAvailable(usernameAvailable)
            setIsUsernameValid(usernameValid)
          })
          .catch((error) => {
            console.error('failed to check username availability', error)
            setError(String(error))
          }),
      600,
    ),
    [],
  )

  const createUser = () => {
    setLoading(true)

    return firebaseApp
      .auth()
      .createUserWithEmailAndPassword(user.email!, user.password!)
      .then(async (userCredential) => {
        // get Firebase token and exchange it for JWT
        const firebaseIDToken = await userCredential?.user!.getIdToken()

        // register user
        const { authToken } = await apiRegisterUser({ firebaseIDToken, user })

        // update token in store
        mainStore.token = authToken

        Analytics.logEvent(AnalyticsEvent.RegisterSuccess, {
          email: user.email,
          username: user.username,
          tags: user.tags,
        })
        navigate('/confirm-email')
      })
      .catch((error) => {
        if (firebaseErrorLabels.has(error.code)) {
          console.debug('expected firebase error', error.code)
          setError(firebaseErrorLabels.get(error.code)!)
        } else {
          console.error('unexpected firebase error', error)
          setError(error.message)

          // delete Fireabase user if API request for registration failed
          firebaseApp.auth().currentUser?.delete()
        }

        Analytics.logEvent(AnalyticsEvent.RegisterFailure, {
          error,
          email: user.email,
          username: user.username,
          tags: user.tags,
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <main css={styles.main}>
      <div css={styles.sidebar.container}>
        <img
          src={require('~/assets/icons/logo-white.svg')}
          alt="logo"
          css={styles.sidebar.logo}
        />

        <h1 css={styles.sidebar.title}>
          Create account <br /> to get started
        </h1>
        <h3 css={styles.sidebar.subtitle}>
          Ryddm is a revolutionary new music discovery platform that will allow users to
          discover the top new tracks, and help musicians promote their music more
          efficiently than ever before.
        </h3>
      </div>

      <div css={styles.form.container}>
        <div css={styles.form.content}>
          <h1 css={styles.form.title}>Get Started!</h1>

          <label css={styles.form.label.primary} htmlFor="name">
            Artist Stage Name
          </label>

          <input
            css={styles.form.input}
            type="text"
            data-testid="input_name"
            required
            autoComplete="name"
            placeholder="Drake"
            onChange={(e) => setUser({ ...user, name: e.target.value })}
          />

          <label css={styles.form.label.primary} htmlFor="username">
            Username
            <span css={styles.form.label.secondary}>
              {' '}
              (letters, numbers, &quot;.&quot; and &quot;_&quot;, max 16 chars)
            </span>
          </label>
          <input
            css={styles.form.input}
            type="text"
            autoComplete="username"
            data-testid="input_username"
            placeholder="champagnepapi"
            onChange={(e) => {
              // get username as input value
              const username = e.target.value

              // we don't know yet whether username is available or not, but we need to disable submit button
              setUsernameAvailable(undefined)
              setIsUsernameValid(undefined)

              // update user with the new username
              setUser({ ...user, username })

              if (username.length > 0) {
                checkUsernameWithDelay(username)
              }
            }}
            required
          />
          <label css={styles.form.label.primary} htmlFor="email">
            Email
          </label>
          <input
            css={styles.form.input}
            type="text"
            autoComplete="email"
            data-testid="input_email"
            placeholder="drake@email.com"
            required
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <label css={styles.form.label.primary} htmlFor="password">
            Password
          </label>
          <input
            css={[styles.form.input, styles.form.inputPassword]}
            type="password"
            autoComplete="new-password"
            data-testid="input_password"
            placeholder="••••••••••••••••••••••••••"
            required
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
          <label css={styles.form.label.primary}>Tags to identify your music</label>
          <div data-testid="input_tags">
            <TagsInput
              onChangeTags={(tags) => setUser({ ...user, tags })}
              tagsLimitExceeded={tagsLimitExceeded}
            />
          </div>

          <div css={styles.checkboxes}>
            <div css={styles.checkbox.container} data-testid="checkbox_container">
              <Checkbox
                termsAccepted={termsAccepted}
                setTermsAccepted={setTermsAccepted}
              />
              <p
                css={styles.checkbox.text}
                onClick={() => setTermsAccepted(!termsAccepted)}
              >
                Agree to our{' '}
                <a css={styles.form.policy} href="/privacy-policy" target="_blank">
                  privacy policy
                </a>{' '}
                and{' '}
                <a css={styles.form.policy} href="/terms" target="_blank">
                  terms of service
                </a>
                .
              </p>
            </div>

            <div css={styles.checkbox.container}>
              <Checkbox
                termsAccepted={emailsAccepted}
                setTermsAccepted={setEmailsAccepted}
              />
              <p
                css={styles.checkbox.text}
                onClick={() => setEmailsAccepted(!emailsAccepted)}
              >
                Agree to receive occasional informational emails
              </p>
            </div>
          </div>

          {error && (
            <p css={styles.form.error} data-testid="error">
              {error}
            </p>
          )}
          {isUsernameValid === false ? (
            <p css={styles.form.error} data-testid="username_error">
              Username is not valid
            </p>
          ) : (
            usernameAvailable === false && (
              <p css={styles.form.error} data-testid="username_not_available">
                Username already taken
              </p>
            )
          )}
          <input
            type="button"
            value={loading ? 'Loading...' : 'Sign Up'}
            css={isSubmitButtonEnabled ? styles.form.submit.on : styles.form.submit.off}
            disabled={!isSubmitButtonEnabled}
            onClick={createUser}
            data-testid="register_button"
          />

          <p css={styles.form.registered}>
            Already registered?{' '}
            <span>
              <Link css={styles.form.link} to="/login" data-testid="link_to_login">
                Sign in
              </Link>
            </span>
          </p>
        </div>
      </div>
    </main>
  )
}
