import { css } from '@emotion/core'

export const styles = {
  main: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 16rem);
    box-sizing: border-box;
  `,

  container: css`
    box-sizing: border-box;

    width: 360px;
    padding: 38px 39px;

    background: #ffffff;
    box-shadow: 0px 0.1px 15px rgba(0, 0, 0, 0.01);
    border-radius: 5px;
  `,

  title: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    color: rgba(0, 0, 0, 0.9);
    pointer-events: none;

    margin: 0px 0px 20px 0px;
  `,

  subtitle: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    color: #a6a6a6;
    pointer-events: none;

    margin: 0px 0px 36px 0px;
  `,

  label: css`
    font-family: Cera Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    color: #585858;

    display: inline-block;
    margin-bottom: 6px;
  `,

  input: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #000000;
    width: 100%;

    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 3px;

    height: 40px;

    padding: 13px 0px 13px 20px;
    outline: none;
    &::placeholder {
      color: #a6a6a6;
    }
    &:disabled {
      cursor: not-allowed;
    }
  `,

  error: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    margin: 4px 0px 20px 0px;

    color: #a10000;
  `,

  button: {
    base: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-align: center;

      width: 100%;
      margin-bottom: 20px;
      padding: 8px 0px 8px 0px;

      color: #ffffff;

      cursor: pointer;
      border: none;
      border-radius: 3px;
    `,
    enabled: css`
      background: rgba(0, 0, 0, 0.9);
    `,
    disabled: css`
      background: rgba(0, 0, 0, 0.4);
      cursor: not-allowed;
    `,
  },

  textContainer: css`
    display: flex;
    justify-content: space-between;
  `,

  textContainerCenter: css`
    justify-content: center;
  `,

  remember: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;

    cursor: default;

    margin: 0px;

    color: rgba(0, 0, 0, 0.6);
  `,

  rememberCenter: css`
    text-align: center;
  `,

  changeEmail: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-decoration: underline;

    margin: 0px;

    cursor: pointer;

    color: #000000;
  `,

  signIn: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    cursor: pointer;

    margin: 0px auto;

    color: #000000;
  `,
}
