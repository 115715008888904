import { FC } from 'react'
import { styles } from './styles'

export const HeaderLogo: FC = () => {
  return (
    <header css={styles.header}>
      <img src={require('~/assets/icons/logo.svg')} alt="logo" data-testid="logo" />
    </header>
  )
}
