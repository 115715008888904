import { css } from '@emotion/core'
import { colors, mq, constants } from '~/styles'

export const styles = {
  header: css``,

  container: css`
    max-width: ${constants.containerSizeSmall};
    margin: auto;
    min-height: 8rem;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${mq.tablet(`flex-direction: column;`)}
  `,

  logo: css`
    outline: none;
    cursor: pointer;

    ${mq.tablet(`order: 1; margin-bottom: 4rem;`)}
  `,

  btns: css`
    display: flex;
    flex-direction: row;
    position: relative;

    ${mq.tablet(`order: 2; width: 100%`)}
  `,

  upload: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${colors.header.upload.btn.color};
    text-decoration: none;
    padding: 8px 16px 8px 16px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${colors.header.upload.btn.bg};
    border-radius: 5px;
    border: none;

    ${mq.tablet(`margin-right: auto;`)}
  `,

  plus: css`
    margin-left: 17px;
  `,

  avatar: css`
    width: 30px;
    height: 30px;

    border-radius: 50%;

    margin-right: 7px;

    cursor: pointer;
  `,

  avatarContainer: css`
    display: flex;
    align-items: center;
    cursor: pointer;

    margin-left: 50px;
  `,

  arrow: css`
    cursor: pointer;
  `,

  logOut: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: ${colors.header.dropdown.logout.color};
    padding: 0px 27px;

    outline: none;
    cursor: pointer;
    border: none;
    background: none;
    &:hover {
      text-decoration: underline;
    }
  `,

  dropdown: {
    container: css`
      width: 144px;
      background: ${colors.header.dropdown.container.bg};
      box-sizing: border-box;
      position: absolute;

      cursor: pointer;

      border: 0.1px solid ${colors.header.dropdown.container.border};
      border-radius: 5px;

      right: 0px;
      top: 44px;
    `,

    itemCreds: css`
      padding: 8px 12px;
      border-bottom: 0.1px solid ${colors.header.dropdown.container.border};
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      &:hover {
        cursor: default;
      }
    `,

    item: css`
      padding: 8px 12px;
      border: none;
      border-top: 0.1px solid ${colors.header.dropdown.container.border};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      &:hover {
        background: ${colors.header.dropdown.container.item.hover};
        cursor: pointer;
      }
    `,

    itemLink: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;

      padding: 8px 12px;

      display: inline-block;
      box-sizing: border-box;
      width: 100%;

      margin: 0px;

      color: ${colors.header.dropdown.container.item.color};

      text-decoration: none;
      &:hover {
        background: ${colors.header.dropdown.container.item.hover};
        cursor: pointer;
      }
    `,

    text: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;

      margin: 0px;

      color: ${colors.header.dropdown.container.item.color};
    `,

    name: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;

      text-align: left;

      color: ${colors.header.dropdown.container.item.color};

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      margin: 0px 0px 2px 0px;
    `,

    username: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;

      text-align: left;

      color: ${colors.header.dropdown.container.username.color};

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      margin: 0px;
    `,
  },
}
