import { css } from '@emotion/core'

export const styles = {
  header: css`
    padding: 50px 70px 0px 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  upload: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    padding: 8px 27px 8px 27px;

    background: rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    border: none;
  `,
}
