import { FC, useState, useEffect, Suspense } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'

import { RequestApiProvider } from '~/contexts'
import { AppContext } from '~/context'
import {
  // CreateAccountScreen,
  // LoginScreen,
  // ConfirmEmailScreen,
  // ResetPasswordScreen,
  // MonetizationConnectedScreen,
  ReferralScreen,
  WelcomeScreen,
  TracksScreen,
  UploadTracksScreen,
  PrivacyPolicyScreen,
  UserAgreementScreen,
  MonetizationScreen,
  ProfileScreen,
  EditProfileScreen,
} from './screens'
import { Header, Footer, Loader } from './components'
import { Analytics, mainStore } from './stores'
import { apiGetUser, apiExchangeDisposableAuthCode } from './api'
import { User } from './types'

export const App: FC = observer(() => {
  const navigate = useNavigate()

  const [user, setUser] = useState<User | undefined>()
  const [loading, setLoading] = useState(false)

  // if url contains `authCode` query, log user in using that code
  useEffect(() => {
    const disposableAuthCode = new URLSearchParams(window.location.search).get('authCode')
    const redirectUrl = new URLSearchParams(window.location.search).get('redirectUrl')

    if (disposableAuthCode) {
      setLoading(true)

      loginWithAuthCode(disposableAuthCode)
        .then(() => {
          // clear `authCode` query after login
          window.history.replaceState({}, document.title, '/tracks')
        })
        .catch((error) => {
          console.error('failed to exchange disposable auth code:', error)
        })
        .finally(() => setLoading(false))
    }

    if (redirectUrl) {
      navigate(redirectUrl)
    }

    if (!redirectUrl && disposableAuthCode) {
      navigate('/tracks')
    }
  }, [])

  useEffect(() => {
    if (mainStore.token) {
      apiGetUser(mainStore.decodedToken.userId)
        .then((res) => {
          setUser(res.user)
          Analytics.setUser(res.user)
        })
        .catch((err) => console.error('failed to get user', err))
    }
  }, [mainStore.token])

  const loginWithAuthCode = async (authCode: string) => {
    // log out current user
    mainStore.token = undefined
    await mainStore.signOut()

    // get auth token and update it in store
    const token = await apiExchangeDisposableAuthCode(authCode)
    mainStore.token = token
    mainStore.emailVerified = true
  }

  if (!mainStore.ready || loading) {
    return null
  }

  if (mainStore.token === '' || mainStore.emailVerified === false) {
    return (
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/welcome" element={<WelcomeScreen />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
          <Route path="/terms" element={<UserAgreementScreen />} />
          <Route path="*" element={<Navigate to="/welcome" replace />} />
        </Routes>
      </Suspense>
    )
  }

  return (
    <AppContext.Provider value={{ user, setUser }}>
      <RequestApiProvider>
        <Header signOut={mainStore.signOut} />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/tracks" element={<TracksScreen />} />
            <Route path="/tracks/upload" element={<UploadTracksScreen />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
            <Route path="/terms" element={<UserAgreementScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/profile/edit" element={<EditProfileScreen />} />
            <Route path="/referral" element={<ReferralScreen />} />
            <Route path="/monetization" element={<MonetizationScreen />} />
            <Route path="*" element={<Navigate to="/tracks" replace />} />
          </Routes>
          <Footer />
        </Suspense>
      </RequestApiProvider>
    </AppContext.Provider>
  )
})
