import mixpanel from 'mixpanel-browser'
import { firebaseApp } from '~/firebase'
import { User } from '~/types'

// AnalyticsEvent provides all possible analytics events.
export enum AnalyticsEvent {
  RegisterSuccess = 'register_success',
  RegisterFailure = 'register_failure',
  LoginSuccess = 'login_success',
  LoginFailure = 'login_failure',
  ResendEmailSuccess = 'resend_email_success',
  ResendEmailFailure = 'resend_email_failure',
  UpdateSubscriptionSettingsSuccess = 'update_subscription_settings_success',
  UpdateSubscriptionSettingsFailure = 'update_subscription_settings_failure',
  RequestPayoutSuccess = 'request_payout_success',
  RequestPayoutFailure = 'request_payout_failure',
  UpdateProfileSuccess = 'update_profile_success',
  UpdateProfileFailure = 'update_profile_failure',
  DeleteTrackSuccess = 'delete_track_success',
  DeleteTrackFailure = 'delete_track_failure',
  UpdateTrackSuccess = 'update_track_success',
  UpdateTrackFailure = 'update_track_failure',
  UploadTrackSuccess = 'upload_track_success',
  UploadTrackFailure = 'upload_track_failure',
}

// Analytics provides functionality for collecting user analytics.
export class Analytics {
  private static google = firebaseApp.analytics()

  public static logEvent(event: AnalyticsEvent, data?: Record<string, any>) {
    try {
      // mixpanel.track(event, data)
      Analytics.google.logEvent(event, data)
    } catch (error) {
      console.error('failed to track event', error)
    }
  }

  public static setUser(user: User) {
    try {
      // set user id
      this.google.setUserId(user.id)
      // mixpanel.identify(user.id)

      // set mixpanel user props
      const { email, name, avatarFileId, ...rest } = user
      // mixpanel.people.set({
      //   ...rest,
      //   $name: name,
      //   $email: email,
      //   $avatar: avatarFileId,
      // })
    } catch (error) {
      console.error('failed to save user data', error)
    }
  }
}
