import { css } from '@emotion/core'

export const styles = {
  main: css`
    display: flex;

    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 16rem);
  `,

  container: css`
    width: 100%;
    max-width: 600px;
  `,

  title: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #000000;

    margin-bottom: 42px;
  `,

  message: {
    container: css`
      display: flex;
      align-items: center;
      flex-direction: column;

      width: 600px;
      box-sizing: border-box;

      background: rgba(196, 196, 196, 0.1);
      border-radius: 3px;
      text-align: center;

      padding: 68px;
      border: 1px solid rgba(0, 0, 0, 0.1);

      margin-bottom: 30px;
    `,

    title: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 21px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);

      margin: 0px;
      margin-bottom: 10px;
    `,

    subtitle: css`
      max-width: 450px;
      box-sizing: border-box;

      word-wrap: break-word;
      font-size: 18px;
      line-height: 24px;
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: normal;
      text-align: center;
      color: #a6a6a6;

      margin: 14px 0 48px 0;

      & p {
        margin: 0px;
      }
    `,
  },

  btn: css`
    background: rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    border: none;
    padding: 8px 27px 8px 27px;
    content: '';
    cursor: pointer;
    outline: none;

    color: #ffffff;

    text-decoration: none;

    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;

    text-align: center;

    color: #ffffff;
  `,

  btnLoading: css`
    background: rgba(0, 0, 0, 0.4);
    border-radius: 3px;
    border: none;
    padding: 8px 27px 8px 27px;
    content: '';
    cursor: pointer;
    outline: none;
    cursor: not-allowed;

    color: #ffffff;
    &:before {
      background: black;
    }
  `,
}
