import { lazy } from 'react'

export * from './auth'

// export const CreateAccountScreen = lazy(() =>
//   import('./auth/create-account').then((t) => ({ default: t.CreateAccountScreen })),
// )

// export const LoginScreen = lazy(() =>
//   import('./auth/login').then((t) => ({ default: t.LoginScreen })),
// )

// export const ConfirmEmailScreen = lazy(() =>
//   import('./auth/confirm-email').then((t) => ({ default: t.ConfirmEmailScreen })),
// )

// export const ResetPasswordScreen = lazy(() =>
//   import('./auth/reset-password').then((t) => ({ default: t.ResetPasswordScreen })),
// )

export const WelcomeScreen = lazy(() =>
  import('./welcome').then((t) => ({ default: t.WelcomeScreen })),
)

export const PrivacyPolicyScreen = lazy(() =>
  import('./privacy-policy').then((t) => ({ default: t.PrivacyPolicyScreen })),
)

export const UserAgreementScreen = lazy(() =>
  import('./user-agreement').then((t) => ({ default: t.UserAgreementScreen })),
)

export const TracksScreen = lazy(() =>
  import('./tracks').then((t) => ({ default: t.TracksScreen })),
)

export const UploadTracksScreen = lazy(() =>
  import('./upload').then((t) => ({ default: t.UploadTracksScreen })),
)

export const ProfileScreen = lazy(() =>
  import('./profile').then((t) => ({ default: t.ProfileScreen })),
)

export const EditProfileScreen = lazy(() =>
  import('./profile-edit').then((t) => ({ default: t.EditProfileScreen })),
)

export const MonetizationScreen = lazy(() =>
  import('./monetization').then((t) => ({ default: t.MonetizationScreen })),
)

export const MonetizationConnectedScreen = lazy(() =>
  import('./monetization-connected').then((t) => ({
    default: t.MonetizationConnectedScreen,
  })),
)

export const ReferralScreen = lazy(() =>
  import('./referral').then((t) => ({ default: t.ReferralScreen })),
)
