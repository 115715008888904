import { User, Track } from '~/types'
import { API_URL } from '~/config'

export const getUserAvatar = (user: User, err: boolean) => {
  const placeholder = require('~/assets/icons/profile-avatar.svg')
  // if failed to load avatar - render placeholder
  // if user have avatar file id - render avatar by id
  // else render placeholder
  return err
    ? placeholder
    : user?.avatarFileId
    ? `${API_URL}/files?fileId=${user.avatarFileId}`
    : placeholder
}

export const getTrackCover = (track: Track, err: boolean) => {
  const placeholder = require('~/assets/icons/track-logo.svg')
  // if failed to load track cover - render placeholder
  // if track have cover file id - render cover by id
  // else render placeholder
  return err
    ? placeholder
    : track.coverFileId
    ? `${API_URL}/files?fileId=${track.coverFileId}`
    : placeholder
}
