import { css } from '@emotion/core'

export const styles = {
  container: css`
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid rgba(0, 0, 0, 0.1);

    border-radius: 3px;
    box-sizing: border-box;
    width: 16px;
    height: 14px;
    cursor: pointer;
    user-select: none;

    margin-right: 10px;
  `,
}
