import { createContext, Dispatch, SetStateAction } from 'react'
import { User } from '~/types'

export const AppContext = createContext<{
  user: User | undefined
  setUser: Dispatch<SetStateAction<User | undefined>>
}>({
  user: undefined,
  setUser: () => undefined,
})
