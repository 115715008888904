import { css } from '@emotion/core'

export const styles = {
  container: css`
    display: flex;

    margin-bottom: 30px;
  `,

  icon: {
    container: css`
      height: 20px;
      display: flex;
      justify-content: center;
    `,

    base: css`
      margin-bottom: -1px;
      margin-right: 5px;
      max-height: 20px;
    `,
  },

  label: css`
    display: block;
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #585858;

    margin-bottom: 6px;
  `,

  button: {
    base: css`
      display: flex;
      justify-content: center;
      align-items: center;

      box-sizing: border-box;
      outline: none;

      background: #ffffff;
      border-radius: 5px;
      border: 2px solid #4b4b4b;

      width: 100%;
      height: 30px;

      color: #4b4b4b;
      cursor: pointer;

      &:first-of-type {
        border-radius: 5px 0px 0px 5px;
      }

      &:last-of-type {
        border-radius: 0px 5px 5px 0px;
      }
    `,

    active: css`
      background: #4b4b4b;
    `,

    disabled: css`
      &:hover {
        cursor: not-allowed;
      }
    `,
  },

  buttonText: {
    base: css`
      font-family: Cera Pro;
      font-weight: 500;
      font-size: 14px;

      padding-top: 2px;
    `,

    active: css`
      color: #ffffff;
    `,
  },
}
