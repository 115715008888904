import { css } from '@emotion/core'

export const styles = {
  main: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 16rem);
  `,

  form: {
    title: css`
      font-family: 'Cera Pro';
      margin: 0px;
      margin-bottom: 32px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #000000;

      margin-bottom: 36px;
    `,
    container: css`
      background: #ffffff;
      width: 360px;
      padding: 38px 39px;
      box-shadow: 0px 0.1px 15px rgba(0, 0, 0, 0.01);
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `,

    label: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: '500';
      font-size: 12px;
      line-height: 14px;
      color: #585858;
      margin-bottom: 6px;
    `,

    error: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #ef3737;
      margin-top: 0px;
      transition: 0.5s;
    `,

    link: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;

      text-align: center;
      text-decoration-line: underline;

      color: #666666;
    `,

    submit: {
      on: css`
        background: rgba(0, 0, 0, 0.9);
        border-radius: 3px;
        border: none;
        width: 100%;

        padding-top: 8px;
        padding-bottom: 8px;

        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;

        margin-top: 0px;
        margin-bottom: 10px;
      `,
      off: css`
        background: rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        border: none;
        width: 100%;

        padding-top: 8px;
        padding-bottom: 8px;

        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;

        margin-top: 0px;
        margin-bottom: 10px;
      `,
    },
  },

  sent: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 350;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    color: #919191;

    margin: 0px;
    margin-bottom: 4px;
  `,

  email: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: normal;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    color: #000000;

    margin: 0px;
    margin-bottom: 15px;
  `,

  check: css`
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 350;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    color: #919191;

    margin: 0px;
    margin-bottom: 36px;

    width: 255px;
  `,
}
