import { reaction, makeAutoObservable } from 'mobx'
import jwtDecode from 'jwt-decode'
import { UI_VERSION } from '~/config'
import { firebaseApp } from '~/firebase'

export class MainStore {
  public token: string | undefined = undefined

  public emailVerified: boolean | undefined = undefined

  public get ready() {
    return this.token !== undefined || this.emailVerified !== undefined
  }

  public get decodedToken() {
    return jwtDecode(this.token!) as { userId: string }
  }

  constructor() {
    // required in mobx 6
    makeAutoObservable(this)

    // get data from localStorage
    const lsData = getLocalStorageData()
    console.debug(lsData)

    // sign user out from updated version (when changing major or minor version)
    this.signOutFromOutdatedUIVersion(lsData[LocalStorageKeys.UI_VERSION])

    this.token = lsData[LocalStorageKeys.TOKEN] || ''
    this.emailVerified = JSON.parse(lsData[LocalStorageKeys.EMAIL_VERIFIED] || 'false')

    // react to token changes
    reaction(
      () => this.token,
      (newToken) => localStorage.setItem(LocalStorageKeys.TOKEN, newToken || ''),
    )

    // react to emailVerified changes
    reaction(
      () => this.emailVerified,
      (newEmailVerified) => {
        localStorage.setItem(
          LocalStorageKeys.EMAIL_VERIFIED,
          JSON.stringify(!!newEmailVerified),
        )

        if (!newEmailVerified) {
          this.emailVerified = firebaseApp.auth().currentUser?.emailVerified || false
        }
      },
    )
  }

  private async signOutFromOutdatedUIVersion(localStorageVersion?: string) {
    if (
      !localStorageVersion ||
      // compare major and minor versions
      localStorageVersion.slice(0, 4) !== UI_VERSION.slice(0, 4)
    ) {
      await this.signOut()
    }

    // update UI version in local storage
    localStorage.setItem(LocalStorageKeys.UI_VERSION, UI_VERSION)
  }

  // @action
  public signOut = async () => {
    await firebaseApp.auth().signOut()
    localStorage.clear()
    localStorage.setItem(LocalStorageKeys.UI_VERSION, UI_VERSION)

    this.token = ''
    this.emailVerified = false
  }
}

enum LocalStorageKeys {
  TOKEN = '@ryddm/token',
  EMAIL_VERIFIED = '@ryddm/emailVerified',
  UI_VERSION = '@ryddm/ui_version',
}

type LocalStorageData = { [key in LocalStorageKeys]: string | undefined }

const getLocalStorageData = () => {
  return Object.values(LocalStorageKeys).reduce(
    (acc, cur) => ({ ...acc, [cur]: localStorage.getItem(cur) || undefined }),
    {} as LocalStorageData,
  )
}

export const mainStore = new MainStore()
