import { css } from '@emotion/core'
import { colors, mq, constants } from '~/styles'

export const styles = {
  footer: css``,

  container: css`
    max-width: ${constants.containerSizeSmall};
    margin: auto;
    min-height: 8rem;
    padding: 2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${mq.tablet(`flex-direction: column;`)}
  `,

  copyright: {
    container: css`
      ${mq.tablet(`margin-bottom: 1.2rem;`)}
    `,
    text: css`
      font-size: 1.4rem;
    `,
  },

  links: css``,

  link: css`
    font-size: 1.4rem;
    text-decoration: none;
    color: inherit;

    &:hover,
    &:active {
    }

    &:link,
    &:visited {
    }

    &:first-of-type {
      margin-right: 10px;
    }
  `,

  logo: css`
    outline: none;
    cursor: pointer;

    ${mq.tablet(`order: 1; margin-bottom: 4rem;`)}
  `,

  btns: css`
    display: flex;
    flex-direction: row;
    position: relative;

    ${mq.tablet(`order: 2; width: 100%`)}
  `,
}
