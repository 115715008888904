import { css } from '@emotion/core'

export const styles = {
  main: css`
    display: flex;

    align-items: center;

    background: #000000;
  `,

  sidebar: {
    container: css`
      background: #000000;

      min-height: 100vh;
      padding: 200px 70px 0px 70px;
      box-sizing: border-box;

      width: 480px;
    `,

    logo: css`
      margin: 0px 0px 72px 0px;
    `,

    title: css`
      font-family: Cera Pro;
      font-style: inherit;
      font-weight: 500;
      font-size: 26px;
      line-height: 33px;

      color: #fcfbfe;

      margin: 0px 0px 38px 0px;
    `,

    subtitle: css`
      font-family: Cera Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;

      max-width: 340px;

      color: rgba(255, 255, 255, 0.8);

      margin: 0px;
    `,
  },

  header: css`
    margin: 50px 0px 50px 70px;
    width: 100%;
  `,

  form: {
    container: css`
      display: flex;
      justify-content: center;
      align-items: center;

      flex-grow: 1;

      box-sizing: border-box;
      min-height: 100vh;

      padding: 40px;

      background: #ffffff;
    `,

    content: css`
      background: #ffffff;
      width: 380px;
      padding: 38px 39px;
      box-shadow: 0px 0.1px 15px rgba(0, 0, 0, 0.01);
      border-radius: 5px;
      box-sizing: border-box;
    `,

    title: css`
      font-family: Cera Pro;
      margin: 0px;
      margin-bottom: 32px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      color: #000000;
    `,

    label: {
      primary: css`
        font-family: Cera Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #585858;

        display: inline-block;
      `,

      secondary: css`
        color: rgba(88, 88, 88, 0.7);
      `,
    },

    input: css`
      margin-top: 6px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 14px;
      /* padding-left: 20px; */
      max-width: 300px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      margin-bottom: 20px;
      box-sizing: border-box;

      font-family: Cera Pro;

      &::placeholder {
        color: rgba(88, 88, 88, 0.4);
      }
    `,
    inputPassword: css`
      letter-spacing: 2px;
      &::placeholder {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #a6a6a6;
        letter-spacing: 2px;
      }
    `,

    select: css`
      background: 'red';
    `,

    policy: css`
      text-decoration: underline;
      cursor: pointer;
      color: #383838;
    `,

    error: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #ef3737;
      margin-top: 0px;
      transition: 0.5s;
    `,

    link: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;

      text-align: center;
      text-decoration-line: underline;

      color: #000000;

      width: 100%;
    `,

    submit: {
      on: css`
        background: rgba(0, 0, 0, 0.9);
        border-radius: 3px;
        border: none;

        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;

        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;

        margin-top: 16px;
        margin-bottom: 10px;

        height: 38px;

        cursor: pointer;
      `,
      off: css`
        background: rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        border: none;

        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;

        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;

        margin-top: 16px;
        margin-bottom: 10px;

        height: 38px;

        cursor: not-allowed;
      `,
    },

    registered: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      float: left;

      color: rgba(0, 0, 0, 0.6);

      width: 100%;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 0px;
    `,
  },

  checkboxes: css`
    margin-top: 16px;
    margin-bottom: 10px;
  `,

  checkbox: {
    container: css`
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      cursor: default;
      &:checked {
        background: red;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    `,

    input: css`
      margin: 0px;
      margin-right: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      border-radius: 0px;
      &:checked {
        background: red;
      }
    `,

    text: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #585858;

      margin-top: 0px;
      margin-bottom: 0px;

      cursor: pointer;
      user-select: none;
    `,
  },
}
