import { css } from '@emotion/core'

export const styles = {
  container: css`
    width: 100vw;
    min-height: 100vh;

    position: absolute;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  `,

  loader: css`
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation: loading 1.1s infinite linear;

    border: 0.7em solid #f3f3f3;
    border-left-color: black;

    &,
    &:after {
      border-radius: 50%;
      width: 4em;
      height: 4em;
    }

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `,
}
