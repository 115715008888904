import { FC, Fragment } from 'react'

import { FieldLoader } from '~/components'
import { styles } from './styles'

type FieldsLoaderProps = {
  fieldsCount: number
  origin: string
}

export const FieldsLoader: FC<FieldsLoaderProps> = ({ fieldsCount, origin }) => {
  return (
    <Fragment>
      {Array.from({ length: fieldsCount }, (_, b) => b).map((v) => {
        return (
          <div key={`field-${v}-${origin}`}>
            <FieldLoader />
            <hr css={styles.hr} />
          </div>
        )
      })}
    </Fragment>
  )
}
