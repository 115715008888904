import { css } from '@emotion/core'

export const styles = {
  main: css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 16rem);
  `,

  header: css`
    margin: 50px 0px 50px 70px;
    width: 100%;
  `,

  form: {
    title: css`
      font-family: 'Cera Pro';
      margin: 0px;
      margin-bottom: 32px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    `,
    container: css`
      background: #ffffff;
      width: 360px;
      padding: 38px 39px;
      box-shadow: 0px 0.1px 15px rgba(0, 0, 0, 0.01);
      border-radius: 5px;
      box-sizing: border-box;
    `,

    label: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: '500';
      font-size: 12px;
      line-height: 14px;
      color: #585858;
      margin-bottom: 6px;
    `,

    input: css`
      margin-top: 6px;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 20px;
      max-width: 282px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      margin-bottom: 20px;
      box-sizing: border-box;
      outline: none;
      &::placeholder {
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #a6a6a6;
      }
    `,

    error: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #ef3737;
      margin-top: 0px;
      transition: 0.5s;
      margin-bottom: 2px;
    `,

    links: css`
      display: flex;
      justify-content: space-between;
    `,

    link: css`
      font-family: 'Cera Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;

      text-align: center;
      text-decoration-line: underline;

      color: #666666;

      display: inline-block;
    `,

    submit: {
      on: css`
        background: rgba(0, 0, 0, 0.9);
        border-radius: 3px;
        border: none;
        color: #ffffff;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;

        margin-top: 19px;
        margin-bottom: 10px;

        cursor: pointer;
      `,

      off: css`
        background: rgba(0, 0, 0, 0.4);
        border-radius: 3px;
        border: none;
        color: #ffffff;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
        font-family: 'Cera Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #ffffff;

        margin-top: 19px;
        margin-bottom: 10px;

        cursor: not-allowed;
      `,
    },
  },
}
