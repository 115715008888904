import { FC, Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { firebaseApp } from '~/firebase'

import { buttonValues } from './values'
import { styles } from './styles'

type ResetPasswordScreenProps = {}

export const ResetPasswordScreen: FC<ResetPasswordScreenProps> = () => {
  const [state, setState] = useState<'initial' | 'send'>('initial')

  const [email, setEmail] = useState('')
  const [seconds, setSeconds] = useState(60)
  const [isCountdownActive, setIsCountdownActive] = useState(false)

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('timeOfLastResetPasswordRequest') !== null) {
      calcTimeDifferenceBetweenRequests()
    }
  }, [])

  const isButtonDisabled = loading || isCountdownActive || email === ''

  const startCountdown = (time: number) => {
    setIsCountdownActive(true)

    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1)
    }, 1000)

    return setTimeout(() => {
      clearInterval(interval)

      setIsCountdownActive(false)
      setSeconds(60)
    }, 1000 * time)
  }

  const sendResetPassword = (emailAddress: string) => {
    setLoading(true)

    return firebaseApp
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        localStorage.setItem('timeOfLastResetPasswordRequest', Date.now().toString())

        setError('')
        setState('send')
        startCountdown(60)
      })
      .catch((error) => {
        console.error(error)

        setError(error.toString())
      })
      .finally(() => setLoading(false))
  }

  const calcTimeDifferenceBetweenRequests = () => {
    const actualTime = Date.now()
    const lastRequestTime = Number(localStorage.getItem('timeOfLastResetPasswordRequest'))
    const timeDifference = Math.floor((actualTime - lastRequestTime) / 1000)

    // if time difference between actual time and last request time more than 60 secods - let
    // user send reset password request one more time
    // if not - start countdown with remain time
    if (timeDifference > 60) {
      setState('initial')

      localStorage.removeItem('timeOfLastResetPasswordRequest')
    } else {
      setSeconds(60 - timeDifference)
      startCountdown(60 - timeDifference)
    }
  }

  return (
    <main css={styles.main}>
      <div css={styles.container}>
        <h1 css={styles.title}>
          {state === 'send' ? 'Email has been sent' : 'Reset password'}{' '}
        </h1>
        <p css={styles.subtitle}>
          {state === 'send'
            ? 'Please check your inbox and click \n  on the received link to reset a password'
            : 'Enter your registered email below \n  to receive password reset instruction'}
        </p>

        {state === 'initial' && (
          <Fragment>
            <label css={styles.label}>Email</label>
            <br />

            <input
              css={error ? styles.input : [styles.input, { marginBottom: '30px' }]}
              type="email"
              disabled={loading}
              placeholder="youremail@example.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
          </Fragment>
        )}

        {error && <p css={styles.error}>{error}</p>}
        <button
          css={
            isButtonDisabled
              ? [styles.button.base, styles.button.disabled]
              : [styles.button.base, styles.button.enabled]
          }
          type="button"
          disabled={isButtonDisabled}
          onClick={() => sendResetPassword(email)}
        >
          {loading
            ? 'Loading...'
            : isCountdownActive
            ? `Resend again in ${seconds}s`
            : buttonValues.get(state)}
        </button>

        <div
          css={
            state === 'send'
              ? styles.textContainer
              : [styles.textContainer, styles.textContainerCenter]
          }
        >
          {state === 'send' && (
            <p
              css={styles.changeEmail}
              onClick={() => {
                setState('initial')
                setEmail('')
              }}
            >
              Change Email
            </p>
          )}
          <p css={styles.remember}>
            Remember password?{' '}
            <Link to="/login" css={styles.signIn}>
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </main>
  )
}
