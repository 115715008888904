import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Global } from '@emotion/core'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing/dist/browser/browsertracing'
import { CaptureConsole } from '@sentry/integrations/dist/captureconsole'
import mixpanel from 'mixpanel-browser'
import firebase from 'firebase/app'

import { global } from '~/styles'
import { App } from './app'
import { RD_PRODUCTION } from './config'

// initialize sentry log collection and monitoring
if (RD_PRODUCTION) {
  mixpanel.init('3cf85bae3344c10f5ea0ae9284bbd24f')
  Sentry.init({
    dsn: 'https://e156a5926e7040b689f181033ad9bf96@o790881.ingest.sentry.io/5922063',
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: 0.5,
  })
} else {
  firebase.analytics().setAnalyticsCollectionEnabled(false)
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Global styles={global} />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)
