export const API_URL = process.env.RD_API_URL || 'https://api-stag.shared.ryddm.com/v1'
console.log(`API_URL: ${API_URL}`)

export const UI_VERSION = process.env.RD_UI_VERSION || 'local'
console.log(`UI_VERSION: ${UI_VERSION}`)

export const RD_PRODUCTION =
  process.env.RD_API_URL === 'https://api-prod.shared.ryddm.com/v1'

export const MIN_LIKES_FOR_MONETIZATION = 3

export const tags = [
  { value: 'acoustic', label: 'Acoustic' },
  { value: 'alternative', label: 'Alternative' },
  { value: 'blues', label: 'Blues' },
  { value: 'children music', label: 'Children Music' },
  { value: 'christian', label: 'Christian' },
  { value: 'classical', label: 'Classical' },
  { value: 'country', label: 'Country' },
  { value: 'dance', label: 'Dance' },
  { value: 'edm', label: 'EDM' },
  { value: 'electronic', label: 'Electronic' },
  { value: 'folk', label: 'Folk' },
  { value: 'futurebass', label: 'FutureBass' },
  { value: 'hip-hop', label: 'Hip-Hop' },
  { value: 'hip-hop_rap', label: 'Hip-Hop / Rap' },
  { value: 'holiday', label: 'Holiday' },
  { value: 'house', label: 'House' },
  { value: 'indie', label: 'Indie' },
  { value: 'industrial', label: 'Industrial' },
  { value: 'instrumental', label: 'Instrumental' },
  { value: 'jazz', label: 'Jazz' },
  { value: 'latin', label: 'Latin' },
  { value: 'metal', label: 'Metal' },
  { value: 'new age', label: 'New Age' },
  { value: 'pop', label: 'Pop' },
  { value: 'r&b_soul', label: 'R&B / Soul' },
  { value: 'reggae', label: 'Reggae' },
  { value: 'rnb', label: 'Rnb' },
  { value: 'rock', label: 'Rock' },
  { value: 'rockabilly', label: 'Rockabilly' },
  { value: 'singer', label: 'Singer' },
  { value: 'soundtrack', label: 'Soundtrack' },
  { value: 'trap', label: 'Trap' },
  { value: 'world', label: 'World' },
]
