import { FC, useRef, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useClickAway } from 'react-use'

import { AppContext } from '~/context'
import { getUserAvatar } from '~/utils'

import { styles } from './styles'

interface HeaderProps {
  signOut: () => unknown
}

export const Header: FC<HeaderProps> = ({ signOut }) => {
  // ref for dropdown component
  const dropdownRef = useRef(null)

  const { user } = useContext(AppContext)
  const [showDropdown, setShowDropdown] = useState(false)
  const [avatarError, setAvatarError] = useState(false)

  // close dropdown when user clicks outside the dropdown
  useClickAway(dropdownRef, () => {
    setShowDropdown(false)
  })

  return (
    <header css={styles.header}>
      <div css={styles.container}>
        <Link to="/tracks" css={styles.logo}>
          <img src={require('~/assets/icons/logo.svg')} alt="logo" data-testid="logo" />
        </Link>

        <div css={styles.btns}>
          <Link to="/tracks/upload" css={styles.upload} data-testid="link_to_upload">
            Upload track
            <img
              src={require('~/assets/icons/plus.svg')}
              alt="Plus Icon"
              css={styles.plus}
            />
          </Link>

          <div
            css={styles.avatarContainer}
            onClick={() => setShowDropdown(!showDropdown)}
            data-testid="dropdown_container"
          >
            <img
              data-testid="user-avatar"
              srcSet={
                user
                  ? getUserAvatar(user, avatarError)
                  : require('~/assets/icons/profile-avatar.svg')
              }
              onError={() => setAvatarError(true)}
              alt="User Avatar"
              css={styles.avatar}
            />

            <img
              src={require('~/assets/icons/dropdown.svg')}
              alt="Arrow Down"
              css={styles.arrow}
              data-testid="arrow-down"
            />
          </div>

          {showDropdown && (
            <div
              css={styles.dropdown.container}
              ref={dropdownRef}
              data-testid="dropdown_body"
            >
              <div css={styles.dropdown.itemCreds}>
                <p css={styles.dropdown.name} data-testid="dropdown_user_name">
                  {user?.name}
                </p>
                <p css={styles.dropdown.username} data-testid="dropdown_user_username">
                  @{user?.username as string}
                </p>
              </div>
              <Link
                css={styles.dropdown.itemLink}
                to="/profile"
                onClick={() => setShowDropdown(false)}
                data-testid="link_to_profile"
              >
                Profile
              </Link>

              <Link
                css={styles.dropdown.itemLink}
                to="/tracks"
                onClick={() => setShowDropdown(false)}
                data-testid="link_to_tracks"
              >
                Tracks
              </Link>

              <Link
                css={styles.dropdown.itemLink}
                to="/monetization"
                onClick={() => setShowDropdown(false)}
                data-testid="link_to_monetization"
              >
                Monetization
              </Link>

              <Link
                css={styles.dropdown.itemLink}
                to="/referral"
                onClick={() => setShowDropdown(false)}
                data-testid="link_to_referral"
              >
                Invite Friends
              </Link>
              <div css={styles.dropdown.item} onClick={signOut} data-testid="log-out-btn">
                <p css={styles.dropdown.text} data-testid="log_out_button">
                  Log out
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  )
}
