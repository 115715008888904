import { requestApi } from './request-api'
import type { User } from './types'

// get user from API
export const apiGetUser = (userID: string) => {
  return requestApi<{ user: User }>({
    url: `/users?user_id=${userID}`,
  }).catch((error) => {
    console.error('failed to get user', error)
    throw error
  })
}

// check is username free or taken
export const apiCheckUsernameAvailability = (username: string) =>
  requestApi<{ usernameAvailable: boolean; usernameValid: boolean }>({
    url: `/auth/username-available/${
      username.charAt(0) === '@' ? username.substring(1) : username
    }`,
  })

export const apiExchangeDisposableAuthCode = (authCode: string) =>
  requestApi<{ authToken: string }>({
    url: '/auth/exchange-auth-code',
    options: {
      method: 'POST',
      body: JSON.stringify({ authCode }),
    },
  }).then((r) => r.authToken)
