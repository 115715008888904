import { FC } from 'react'
import { useTimeout } from 'react-use'
import { styles } from './styles'

export const Loader: FC = () => {
  const [ready] = useTimeout(500)

  if (!ready()) {
    return null
  }

  return (
    <div css={styles.container}>
      <div css={styles.loader}>Loading...</div>
    </div>
  )
}
