import { Styles } from 'react-select'

export const selectStyles = {
  placeholder: () => ({
    fontFamily: 'Cera Pro',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    color: 'rgba(88, 88, 88, 0.4)',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  input: () => ({}),

  multiValueLabel: () => ({
    fontFamily: 'Cera Pro',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#FBFBFB',
    marginRight: '1px',
  }),

  multiValue: () => ({
    background: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    flexDirection: 'row',
    padding: '6px 4px 6px 12px',
    margin: '4px',
    className: 'option',
    lineHeight: '0px',
    borderRadius: '5px',
  }),

  multiValueRemove: () => ({
    color: 'rgba(251, 251, 251, 0.8)',
    marginRight: '3px',
    cursor: 'pointer',
  }),

  option: (provided: any) => ({
    ...provided,
    fontFamily: 'Cera Pro',
    fontStyle: 'normal',
    fontWeight: '400',
    color: 'grey',
    background: 'white',

    ':hover': {
      background: 'rgba(0, 0, 0, 0.7);',
      color: 'white',
    },
  }),

  noOptionsMessage: (provided: any) => ({
    ...provided,
    fontFamily: 'Cera Pro',
    fontStyle: 'normal',
    fontWeight: '400',
    color: 'grey',
  }),

  valueContainer: (provided: any) => ({
    ...provided,
    padding: '4px 10px',
  }),

  indicatorsContainer: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
  }),

  control: (provided: any) => ({
    ...provided,
    border: '1px solid rgba(0,0,0,0.1)',
    boxShadow: 'none',
    outline: 'none',
    minHeight: '43px',
    marginTop: '6px',
    cursor: 'pointer',
    width: '300px',
    // @ts-expect-error
    minHeight: '38px',
    ':focus': {
      border: '1px solid rgba(0,0,0,0.1)',
    },
    ':hover': {
      borderColor: 'rgba(0,0,0,0.1)',
    },
  }),
}
