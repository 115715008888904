import { createContext, FC, useMemo } from 'react'
import { DefaultApi } from '@ryddm-inc/rd-apiclient-old'

import { API_URL } from '~/config'
import { RequestApiParams, RequestApiService } from '../request-api'
import { mainStore } from '~/stores'

type RequestApiContextProps = {
  requestApi: <T = void>(params: RequestApiParams) => Promise<T>
  api: DefaultApi
}

export const RequestApiContext = createContext<RequestApiContextProps>({
  requestApi: () => undefined!,
  api: undefined!,
})

export const RequestApiProvider: FC = (props) => {
  const { token, signOut } = mainStore

  // initialize services
  const requestApiService = useMemo(() => new RequestApiService(signOut), [])
  const apiService = useMemo(() => {
    return new DefaultApi({ apiKey: `Bearer ${token}` }, API_URL, fetch)
  }, [token])

  return (
    <RequestApiContext.Provider
      {...props}
      value={{
        requestApi: requestApiService.requestApi!,
        api: apiService,
      }}
    />
  )
}
