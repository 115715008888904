import { API_URL } from './config'
import { mainStore } from './stores'

export type RequestApiParams = {
  url: string
  authToken?: string
  options?: RequestInit
  formData?: boolean
}

export const requestApi = async <T = void>(params: RequestApiParams): Promise<T> => {
  const res = await fetch(API_URL + params.url, {
    ...params.options,
    headers: {
      // remove content-type on file upload
      ...(!params.formData && { 'Content-Type': 'application/json' }),
      Authorization: `Bearer ${params.authToken}` || '',
      ...params.options?.headers,
    },
  })

  // if status of response is 401, sign user out
  if (res.status === 401) {
    console.debug('unauthorized user')
    mainStore.signOut()
  }

  // if status of response is 403, sign user out
  if (res.status === 403) {
    console.debug('blocked user')
    mainStore.signOut()
  }

  const data = await res.json()

  if (!res.ok) {
    throw data
  }

  return data
}

export class RequestApiService {
  // eslint-disable-next-line no-useless-constructor
  constructor(private signOut: () => void) {}

  public requestApi = async <T = void>(params: RequestApiParams): Promise<T> => {
    const res = await fetch(API_URL + params.url, {
      ...params.options,
      headers: {
        // remove content-type on file upload
        ...(!params.formData && { 'Content-Type': 'application/json' }),
        Authorization: `Bearer ${params.authToken}`,
        ...params.options?.headers,
      },
    })

    // if status of response is 401, sign user out
    if (res.status === 401) {
      console.debug('unauthorized user')
      this.signOut()
    }

    const data = await res.json()

    if (!res.ok) {
      throw data
    }

    return data
  }
}
