import { FC, Fragment, Dispatch, SetStateAction } from 'react'
import { Track, TrackCreate } from '~/types'

import { styles } from './styles'

interface VisibilityProps {
  track: Partial<Track> | TrackCreate
  setTrack: Dispatch<SetStateAction<Partial<TrackCreate>>>
  isDisabled: boolean
}

export const Visibility: FC<VisibilityProps> = ({ setTrack, track, isDisabled }) => {
  return (
    <Fragment>
      <label css={styles.label} data-testid="track_file_input_label">
        Who can listen to the track?
      </label>

      <div css={styles.container}>
        <button
          type="button"
          disabled={isDisabled}
          onClick={() =>
            setTrack((state) => ({
              ...state,
              visibility: 'public',
              unlockPrice: 0,
            }))
          }
          css={[
            styles.button.base,
            track.visibility === 'public' && styles.button.active,
            isDisabled && styles.button.disabled,
          ]}
        >
          <div css={styles.icon.container}>
            <img
              css={styles.icon.base}
              alt="Closed lock icon"
              src={
                track.visibility === 'public'
                  ? require('~/assets/icons/lock-open-white.svg')
                  : require('~/assets/icons/lock-open-grey.svg')
              }
            />
          </div>
          <span
            css={[
              styles.buttonText.base,
              track.visibility === 'public' && styles.buttonText.active,
            ]}
          >
            Anybody
          </span>
        </button>
        <button
          type="button"
          disabled={isDisabled}
          onClick={() =>
            setTrack((state) => ({ ...state, visibility: 'subscribers', unlockPrice: 1 }))
          }
          css={[
            styles.button.base,
            track.visibility === 'subscribers' && styles.button.active,
            isDisabled && styles.button.disabled,
          ]}
        >
          <div css={styles.icon.container}>
            <img
              css={styles.icon.base}
              alt="Open lock icon"
              src={
                track.visibility === 'subscribers'
                  ? require('~/assets/icons/lock-closed-white.svg')
                  : require('~/assets/icons/lock-closed-grey.svg')
              }
            />
          </div>
          <span
            css={[
              styles.buttonText.base,
              track.visibility === 'subscribers' && styles.buttonText.active,
            ]}
          >
            Subscribers
          </span>
        </button>
      </div>
    </Fragment>
  )
}
