import { FC } from 'react'
import { Link } from 'react-router-dom'

import { styles } from './styles'

type FooterProps = {}

export const Footer: FC<FooterProps> = () => (
  <footer css={styles.footer}>
    <div css={styles.container}>
      <div css={styles.copyright.container}>
        <span css={styles.copyright.text}>© 2022 Ryddm Inc.</span>
      </div>
      <div css={styles.links}>
        <Link to="/privacy-policy" css={styles.link}>
          Privacy Policy
        </Link>
        <Link to="/terms" css={styles.link}>
          Terms of Use
        </Link>
      </div>
    </div>
  </footer>
)
