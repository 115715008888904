import { FC } from 'react'
import Select from 'react-select'

import { tags } from '~/config'
import { selectStyles } from './styles'

interface TagsInputProps {
  onChangeTags: (tags: string[]) => void
  tagsLimitExceeded: boolean | undefined
}

export const TagsInput: FC<TagsInputProps> = ({ onChangeTags, tagsLimitExceeded }) => {
  const onTagsChange = (
    value: any,
    // actionMeta: ActionMeta<OptionType>,
  ) => {
    onChangeTags(value?.map((item: { value: string }) => item.value))
  }

  return (
    <Select
      options={tags}
      closeMenuOnSelect
      isMulti
      styles={selectStyles as any}
      placeholder="Acoustic, Rock"
      noOptionsMessage={() => 'No genres'}
      required
      menuIsOpen={tagsLimitExceeded ? undefined : false}
      isSearchable={tagsLimitExceeded}
      // onChange={(e: any) => onChangeTags(e?.map((item: { value: string }) => item.value))}
      onChange={(value) => onTagsChange(value)}
    />
  )
}
