import { FC } from 'react'
import { styles } from './styles'

interface CheckboxProps {
  termsAccepted: boolean
  setTermsAccepted: (checked: boolean) => any
}

export const Checkbox: FC<CheckboxProps> = ({ termsAccepted, setTermsAccepted }) => {
  return (
    <div
      css={styles.container}
      onClick={() => setTermsAccepted(!termsAccepted)}
      data-testid="checkbox"
    >
      {termsAccepted && (
        <img src={require('~/assets/icons/checkbox.svg')} alt="Checkbox" />
      )}
    </div>
  )
}
