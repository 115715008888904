import { css } from '@emotion/core'

export const constants = {
  containerSizeSmall: '1140px',
  containerSizeBig: '1440px',
}

// mq represents media queries module
export const mq = {
  mobile: (css: string): string => {
    return `@media only screen and (max-width: 26.563em) {${css}}`
  },
  tablet: (css: string): string => {
    return `@media only screen and (max-width: 48em) {${css}}`
  },
  laptop: (css: string): string => {
    return `@media only screen and (max-width: 64em) {${css}}`
  },
  desktop: (css: string): string => {
    return `@media only screen and (max-width: 90em) {${css}}`
  },
}

export const global = css`
  :root {
    --font-primary: 'Cera Pro', sans-serif;
    --cl-font: #777;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    background-color: var(--cl-bg-dark);

    @media only screen and (max-width: 48em) {
      font-size: 56.25%;
    }
    @media only screen and (max-width: 26.563em) {
      font-size: 50%;
    }
  }
  // 100% / 16px * 10px = 1rem = 62.5% 10px
  // 100% / 16px * 9px = 1rem = 56.25% 9px
  // 100% / 16px * 8px = 1rem = 50% 8px

  body {
    font-family: var(--font-primary), -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    color: var(--cl-font);
  }
`
// colors represents color pallet
export const colors = {
  error: {
    color: '#ef3737',
  },
  success: {
    color: '#06b75b',
  },
  monetizationScreen: {
    banner: {
      border: 'rgba(0,0,0,0.2)',
      settings: {
        svg: {
          fill: '#777',
        },
      },
    },
    button: {
      color: '#777',
      bg: '',
      border: 'rgba(0,0,0,0.2)',
    },
    transaction: {
      container: {
        after: {
          color: 'rgba(0,0,0,0.05)',
        },
      },
    },
  },
  profileScreen: {
    container: {
      bg: '#fff',
    },
    name: {
      color: 'rgba(0, 0, 0, 0.9)',
    },
    username: {
      color: '#585858',
    },
    followers: {
      amount: {
        color: '#000',
      },
      text: {
        color: '#666666',
      },
    },
    tags: {
      tag: {
        bg: 'rgba(0, 0, 0, 0.9)',
      },
      text: {
        color: '#fbfbfb',
      },
    },
    edit: {
      color: '#313131',
    },
  },
  profileEditScreen: {
    container: {
      bg: '#fff',
    },
    choosePhoto: {
      color: '#585858',
    },
    star: {
      color: '#a10000',
    },
    input: {
      color: '#000000',
      border: 'rgba(0, 0, 0, 0.1)',
      label: '#585858',
    },
    cancel: {
      color: 'rgba(0, 0, 0, 0.9)',
    },
    save: {
      bg: 'rgba(0, 0, 0, 0.9)',
      color: '#fff',

      inactive: {
        bg: 'rgba(0, 0, 0, 0.4)',
        color: '#fff',
      },
    },
  },
  tracksScreen: {
    title: {
      color: '#000',
    },
    hr: {
      border: 'rgba(0, 0, 0, 0.05)',
    },
    track: {
      title: {
        color: 'rgba(0, 0, 0, 0.9)',
      },
      subtitle: {
        color: 'rgba(0, 0, 0, 0.6)',
      },
      duration: {
        color: 'rgba(0, 0, 0, 0.7)',
      },
    },
    modal: {
      container: {
        bg: '#fbfbfb',
      },
      choose: {
        color: '#585858',
      },
      label: {
        color: '#585858',
      },
      input: {
        border: 'rgba(0, 0, 0, 0.1)',
        placeholder: '#a6a6a6',
      },
      button: {
        black: {
          color: '#fff',
          bg: 'rgba(0, 0, 0, 0.9)',
        },
        white: {
          color: 'rgba(0, 0, 0, 0.9)',
        },
      },
    },
  },
  tracksUploadScreen: {
    modal: {
      container: {
        bg: '#fbfbfb',
      },
      choosePhoto: {
        color: '#585858',
        bg: 'rgba(0, 0, 0, 0.9)',
      },
    },
    cover: {
      container: {
        bg: '#f6f6f6',
      },
    },
    values: {
      container: {
        color: '#888888',
      },
      selected: {
        color: '#4b4b4b',
      },
    },
    sizeLimit: {
      color: '#898989',
    },
    selectFile: {
      bg: 'rgba(0, 0, 0, 0.7)',
      color: ' #fff',
    },
    buttonCancel: {
      color: 'rgba(0, 0, 0, 0.9)',
      loading: {
        color: 'rgba(0, 0, 0, 0.4)',
      },
    },
    btnUpload: {
      color: '#fff',
      bg: 'rgba(0, 0, 0, 0.9)',
      loading: {
        color: '#ffffff',
        bg: 'rgba(0, 0, 0, 0.4)',
      },
    },
    label: {
      color: '#585858',
    },
    input: {
      color: '#000000',
      border: 'rgba(0, 0, 0, 0.1)',
      placeholder: '#a6a6a6',
    },
  },
  tracksEditModal: {
    overlay: {
      bg: 'rgba(0, 0, 0, 0.5)',
    },
    modal: {
      container: {
        bg: ' #fbfbfb',
      },
    },
    preview: {
      container: {
        bg: '#f6f6f6',
      },
    },
    choose: {
      color: '#585858',
      hover: {
        color: 'rgba(0, 0, 0, 0.9)',
      },
    },
    label: {
      color: '#585858',
    },
    input: {
      border: 'rgba(0, 0, 0, 0.1)',
      placeholder: {
        color: '#a6a6a6',
      },
    },
    btn: {
      black: {
        color: '#fff',
        bg: 'rgba(0, 0, 0, 0.9)',
      },
      white: {
        color: 'rgba(0, 0, 0, 0.9)',
      },
      loading: {
        color: '#ffffff',
        bg: 'rgba(0, 0, 0, 0.4)',
      },
    },
    values: {
      container: {
        color: '#888888',
      },
      selected: {
        color: '#4b4b4b',
      },
    },
  },
  header: {
    upload: {
      btn: {
        color: '#fff',
        bg: 'rgba(0, 0, 0, 0.9)',
      },
    },
    dropdown: {
      container: {
        bg: '#f3f3f3',
        border: 'rgba(0, 0, 0, 0.2)',
        item: {
          color: 'rgba(0, 0, 0, 0.8)',
          hover: '#e4e4e4',
        },
        username: {
          color: 'rgba(0, 0, 0, 0.5)',
        },
      },
      logout: {
        color: '#585858',
      },
    },
  },
  welcomeScreen: {
    left: {
      title: '#fcfbfe',
      subtitle: 'rgba(255, 255, 255, 0.8)',
      container: {
        bg: '#000',
      },
    },
    right: {
      container: {
        bg: '#fff',
      },
    },
  },
}
