import { requestApi } from '~/request-api'
import type { User } from '~/types'

export const apiLoginUser = (firebaseIDToken: string) =>
  requestApi<{ authToken: string; user: User }>({
    url: '/auth/login',
    options: {
      method: 'POST',
      body: JSON.stringify({ idToken: firebaseIDToken }),
    },
  })
