import { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { AuthLoginUser } from '~/types'
import { firebaseApp } from '~/firebase'
import { Analytics, mainStore, AnalyticsEvent } from '~/stores'
import { firebaseErrorLabels } from '~/errors'

import { styles } from './styles'
import { apiLoginUser } from './api'

interface LoginScreenProps {}

export const LoginScreen: FC<LoginScreenProps> = () => {
  const navigate = useNavigate()

  const [user, setUser] = useState<AuthLoginUser>({ email: '', password: '' })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const areCredentialsFilled = Boolean(user.email?.trim() && user.password)
  const isButtonDisabled = areCredentialsFilled && !loading

  const signIn = () => {
    setLoading(true)

    return firebaseApp
      .auth()
      .signInWithEmailAndPassword(user.email!, user.password!)
      .then(async (data) => {
        const firebaseToken = await data.user!.getIdToken()
        const { authToken, user } = await apiLoginUser(firebaseToken)

        // check if user is artist
        if (user.role !== 'artist') {
          throw new Error('Access denied')
        }

        mainStore.token = authToken

        if (data.user?.emailVerified) {
          mainStore.emailVerified = true
        } else {
          // send email verification to user
          await data.user?.sendEmailVerification()
          navigate('/confirm-email')
        }

        Analytics.logEvent(AnalyticsEvent.LoginSuccess, user)
      })
      .catch((error) => {
        if (firebaseErrorLabels.has(error.code)) {
          console.debug('failed to login', error)
          setError(firebaseErrorLabels.get(error.code) as string)
        } else {
          console.error('failed to login', error)
          setError(error.message)
        }
        Analytics.logEvent(AnalyticsEvent.LoginFailure, { ...user, error })
      })
      .finally(() => setLoading(false))
  }

  return (
    <main css={styles.main}>
      <div css={styles.form.container}>
        <h1 css={styles.form.title}>Log in</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            signIn()
          }}
        >
          <label css={styles.form.label}>Email</label>
          <br />
          <input
            css={styles.form.input}
            type="text"
            placeholder="youremail@example.com"
            autoComplete="email"
            data-testid="input_email"
            required
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <br />
          <label css={styles.form.label}>Password</label>
          <br />
          <input
            css={styles.form.input}
            style={{ marginBottom: 15 }}
            type="password"
            autoComplete="current-password"
            placeholder="··············"
            data-testid="input_password"
            required
            onChange={(e) => setUser({ ...user, password: e.target.value })}
          />
          <br />

          {error && (
            <p css={styles.form.error} data-testid="error">
              {error}
            </p>
          )}

          <input
            type="submit"
            data-testid="login_button"
            value={loading ? 'Loading...' : 'Log in'}
            css={isButtonDisabled ? styles.form.submit.on : styles.form.submit.off}
            disabled={!isButtonDisabled}
          />
        </form>

        <div css={styles.form.links}>
          <Link
            css={styles.form.link}
            to="/create-account"
            data-testid="link_to_register"
          >
            Create account
          </Link>

          <Link
            css={styles.form.link}
            to="/reset-password"
            data-testid="link_to_register"
          >
            Forgot password?
          </Link>
        </div>
      </div>
    </main>
  )
}
